<template>
  <page-container class="userNamePswLogin-container">
    <van-form
        @submit="login"
        label-width="4.5em"
        style="margin-top: 2em"
    >
      <div class="form-card">
        <van-field
            v-model.trim="userLoginForm.username"
            name="username"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
            v-model.trim="userLoginForm.password"
            name="password"
            label="密码"
            placeholder="密码"
            border
            :rules="[{ required: true, message: '请填写密码' }]"
        />
      </div>

      <van-button block round size="small" type="primary" native-type="submit">登录</van-button>
    </van-form>
  </page-container>

</template>

<script>
import { oldUserLogin } from '@/api/system';
import { SAVE_TOKEN } from '@/util/storage';
import { Toast } from 'vant';

export default {
  name: 'userNamePswLogin',
  data() {
    return {
      userLoginForm: {
        username: '',
        password: '',
      },
    };
  },
  methods: {
    login() {
      oldUserLogin({ ...this.userLoginForm }).then(res => {
        if (res) {
          SAVE_TOKEN('');
          this.$router.back();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.userNamePswLogin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;

  .form-card {
    border-radius: 10px;
    overflow: hidden;
  }

  .van-form {
    margin: 20px 0 0 0;
    width: 100%;
    overflow: hidden;
  }

  .van-button {
    margin-top: 20px;
  }
}
</style>
